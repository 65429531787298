<template>
  <div>
    <div class="header">
      <van-nav-bar
        title="存款记录"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
		<van-row>
			<van-list
			  v-if="recharges.length"
			  v-model="loading"
			  :finished="finished"
			  :finished-text="$t('common.nomore')"
			>
			  <van-row
			    class="currency-Increase"
			    v-for="item in recharges"
			    :key="item.id"
				@click="todetail(item)"
			  >
			    <van-col
			      :span="12"
			      style="text-align:left"
			    >
					<div>类型:充值</div>
					<div>状态:
						<span v-if="item.status === '0'">
						  {{ $t('dai-shen-he') }} </span>
						<span v-if="item.status === '1'">
						  {{ $t('tong-guo') }} </span>
						 <span v-if="item.status === '2'">
						  {{ $t('bo-hui') }} </span>
					 </div>
					<div>提交时间：{{ item.createtime | dateformat }}</div>
			    </van-col>
			    <van-col
			      :span="12"
			      class="currency-time"
			    >
					<div>金额 </div>
					<div style="color: #e75671;font-weight: bold;font-size: 1.5rem;margin-top: 10px;">{{item.recharge_num}} 
						<span style="font-size: 0.8rem;">{{item.product_type}}</span>
					 </div>
			    </van-col>
			  </van-row>
			</van-list>
			<van-empty
			  :description="$t('common.nomore')"
			  v-else
			/>
		</van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
      recharges: [],
      withdraws: [],
      transfers: [],
      finished: true,
      loading: false,
	  ordertype:1,
    }
  },
  created() {
     this.getRecharges()
   // this.getTransfers() 
	//this.getOrders();
  },
  mounted() {},
  methods: {
	  todetail(item){
		this.$router.push("/rechargeinfo/"+item.id);  
	  },
	  selorder(type){
		this.ordertype=type;  
		this.getOrders();
	  },
    // 创新记录
    async getOrders() {
      const { data } = await this.$http.get('/home/trade/get_orders')
      if (data) {
        if (data.code === 200) {
          this.orders = data.data
        }
      }
    },
    // 充值记录
    async getRecharges() {
      const { data } = await this.$http.get('/home/trade/get_recharges')
      if (data) {
        if (data.code === 200) {
          this.recharges = data.data
        }
      }
    },
    // 提币记录
    async getWithdraws() {
      const { data } = await this.$http.get('/home/trade/get_withdraws')
      if (data) {
        if (data.code === 200) {
          this.withdraws = data.data
        }
      }
    },
    // 提币记录
    async getTransfers() {
      const { data } = await this.$http.get('/home/trade/get_transfers')
      if (data) {
        if (data.code === 200) {
          this.transfers = data.data
        }
      }
    },
    change(name) {
      console.log(name)
      switch (name) {
        case 'tab2':
          this.getOrders()
          break
        case 'tab1-1':
          this.getRecharges()
          break
        case 'tab1-2':
          this.getWithdraws()
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
	.red_txt{
		color: #e75671;
	}
	.green_txt{
		color: #28be86;
	}
	.nav_wrap{
		display: flex;
		background-color: #ff571e;
		.nav_item{
			width: 50%;
			padding: 20px 0;
			text-align: center;
			color: #cad0e6;
		}
		.active_nav{
			color: #ff571e;
			background-color: #181e34;
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
.maincontent {
  padding: 46px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    font-size: 1.04rem;
  }
  .currency-Increase {
    color: #8686aa;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    line-height: 1.6rem;
    font-size: 0.8rem;
    display: flex;
    border-bottom: 0.026667rem solid #23293f;
    .currency-time {
      text-align: right;
    }
  }
}
</style>
